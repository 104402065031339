<template>
  <div style="margin-bottom:100px;">
    <van-sticky :offset-top="0" v-if="ShowReturn">
      <demo-block :title="('')">
        <van-nav-bar :title="('购物车')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <div :style="'margin-bottom: ' + IsRemainBlank ? 185 : 90 + 'px;'">
      <van-cell-group inset v-for="item in goods" :key="item.id" :name="item.id" style="margin-bottom: 10px;">
        <van-checkbox-group class="card-goods" v-model="checkedGoods">
          <van-checkbox
            class="card-goods__item"
            :key="item.id"
            :name="item.id"
            label-disabled
          >
            <van-card
              :title="item.title"
              :desc="item.desc"
              :price="formatPrice(item.price)"
              :thumb
              ="item.imageurl"
            >
                <template #tags>
                  <van-icon name="delete" size="28" color="#ee0a24" @click="RemoveGoods(item.id)"/>
                </template>              
              <template #num>
                <van-stepper v-model="item.num" theme="round" button-size="20" min="1" disable-input />
              </template>
            </van-card>
          </van-checkbox>
        </van-checkbox-group>
      </van-cell-group>
    </div>
    <van-submit-bar
      :price="totalPrice"
      :disabled="!checkedGoods.length"
      :button-text="submitBarText"
      @submit="onSubmit"
      :class="IsRemainBlank?'van-submit-bar':'van-submit-bar1'"
    >
     <van-checkbox v-model="checkAll">全选</van-checkbox>
     
    </van-submit-bar>
  </div>
</template>

<script>
import { CellGroup,  Checkbox, CheckboxGroup, Card, SubmitBar, Toast, Sticky, NavBar, Icon, Stepper } from 'vant';

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Stepper.name]: Stepper,
    [CellGroup.name]: CellGroup
  },
  props: {
    ShowReturn: Boolean,
    IsRemainBlank: Boolean,
    title: String,
  },
  data() {
    return {
      checkedGoods: [],
      goods: [],
      checkAll: false
    };
  },
  watch: {
    checkAll(val) {
      console.log(val)
      if (val === true) {
        this.checkedGoods = []
        this.goods.forEach(info => {
          this.checkedGoods.push(info.id)
        });
      }
      else
        this.checkedGoods = []
    }
  },
  computed: {
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },

    totalPrice() {
      console.log(this.goods)
      var total = 0
      this.goods.forEach(item => {
        if (this.checkedGoods.indexOf(item.id) !== -1) {
          total = total + item.price * item.num
        }
      });
      console.log(total)
      return total * 100
      // return this.goods.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price * item.num : 0), 0);
    }
  },
  mounted() {
    console.log('购物车进入')
    this.goods = this.$store.state.cartlist;
    this.checkedGoods = []
  },
  methods: {
    RemoveGoods(id) {
      this.goods.forEach((info, index) => {
        if (info.id === id) {
          this.goods.splice(index, 1);
          return;
        }
      });
      this.$store.state.cartlist = this.goods;
    },
    formatPrice(price) {
      return (price / 1).toFixed(2);
    },

    onSubmit() {
      var cartlist = []
      Toast('点击结算,开始生成购物车');
      console.log(this.checkedGoods)
      this.goods.forEach(item => {
        if (this.checkedGoods.indexOf(item.id) !== -1) {
           cartlist.push(item)
        }
      });
      // console.log(cartlist)
      if (cartlist.length === 0) {
        Toast('购物车为空');
      } else {
        this.$store.state.cartlist = cartlist
        // this.$router.push('order');
        this.$router.push({ path: 'order?' + new Date() });
        // console.log(cartlist)
      }
    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  // background-color: #fff;

  &__item {
    position: relative;
    // background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
.van-card {
  background: #ffffff
}
.van-submit-bar {
  bottom: 52px;
}
.van-submit-bar1 {
  bottom: 0px;
}
</style>
