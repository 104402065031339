<template>
  <demo-block v-if="active === 0">
    <van-sticky :offset-top="0">
      <van-search
        v-model="searchid"
        show-action
        :label="'搜索'"
        :placeholder="'请输入商品内容'"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch(searchid)">{{ "搜索" }}</div>
        </template>
      </van-search>
    </van-sticky>
  </demo-block>

  <demo-block v-if="active === 0" :title="''">
    <van-tabs v-model:active="activeType">
      <van-tab v-for="shop in shoptypelist" :title="shop.title" :key="shop.id" :name="shop.id" style="margin-bottom:50px;">
        <demo-block>
          <van-swipe v-if="shop.key === 'home'"
            :autoplay="3000"
            @change="onChange"
            style="height: 150px"
            ref="swipe"
          >
            <van-swipe-item v-for="(image, index) in images" :key="index">
              <van-image :src="image" />
            </van-swipe-item>
          </van-swipe>
            <div style="margin-bottom:40px;" ref="tab">
              <div id="good" class="bgcolor">
                <div class="goods-item-wrapper" v-for="goods in goodslist" :key="goods.id" @click="ClickBuy(goods.id)">
                  <van-row type="flex" justify="center">
                    <van-image
                      fit="contain"
                      :src="goods.imageurl"
                      class="img1"
                    />
                  </van-row>
                  <van-row class="goodinfo">
                    <span class="goodinfo">
                      {{goods.goodinfo}}
                    </span>
                  </van-row>
                  <van-row class="goodinfo">
                    <van-col span="23">
                      <span style="color:red;text-align:left">
                        {{goods.hotinfo}}
                      </span>
                       
                    </van-col>
                    
                  </van-row>
                  <van-row gutter="1">
                    <van-col span="23" ><span style="color:green;text-align:left">
                        {{'1000+'}}
                      </span>
                      <span style="color:blue;text-align:left;margin-left:4px;">
                        {{'外购商品'}}
                      </span>
                      <span style="color:blue;text-align:left;margin-left:4px;">
                        {{'经常购买'}}
                      </span>
                    </van-col>
                  </van-row>
                  <van-row gutter="2">
                    <van-col span="10" ><div class="cny">￥{{goods.price}}              </div></van-col>
                    <van-col span="10" class="van-col-right">
                        
                          <van-icon name="cart" size="1.5rem" color="#ee0a24" @click="ClickBuy(goods.id)" />
                        
                      </van-col>
                  </van-row>
              </div>
            </div>
            </div>
        </demo-block>
      </van-tab>
    </van-tabs>
  </demo-block>
  <demo-block v-if="active === 1" :title="'分类信息'">
    <van-tree-select
      v-model:main-active-index="sortIndex"
      :items="sortItems"
      height="100vh"
    >
      <template #content>
        <van-grid :column-num="3">
          <van-grid-item v-for="s in sortList" :key="'key' + s.id" @click="ClickBuy(s)">
          <van-image :src="s.imageurl" />
          {{ s.goodinfo.substr(1,5) }}
          </van-grid-item>
        </van-grid>
      </template>
    </van-tree-select>
    
  </demo-block>

  <demo-block v-if="active === 2" style="margin-bottom: 20px">
      <div>
        <div style="margin-bottom: 90px;">
         <cart-list :ShowReturn="false" :buttom="50" :IsRemainBlank="true" />
        </div>
    </div>
  </demo-block>
<demo-block v-if="active === 3" style="margin-top: 10px">
    <div class="m-img" :style="'background-image:url('+ require('/assets/images/personbg.jpg')+')'">
      <van-row justify="space-between" align="center">
        <van-col span="5" align="center">
          <van-image
            class="title-img"
            round
            width="5rem"
            height="5rem"
            src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
          />
        </van-col>
        <van-col span="10"  offset="0" align="left">
          <van-row>
            <font color="ff0000" aria-setsize="10">{{this.$store.state.loginuser}}</font>
          </van-row>
          <van-row>
            <span color="#ff0000">积分(0)</span>
          </van-row>
        </van-col>
        <van-col span="4"  offset="1">
          <van-button round :icon="require('/assets/images/qrcode.png')" size="mini">会员码</van-button>
        </van-col>
      </van-row>
      <val-row gutter="2" justify="center" align="center">
        <van-col span="6" align="center">购物：100</van-col>
        <van-col span="10">积分：20</van-col>
        <van-col span="6">普通会员</van-col>
      </val-row>
    </div>
    <div>
      <van-cell-group inset>
      
        <van-cell title="我的订单" icon="shopping-cart" text="查看全部订单" is-link to="orderlist?type=0"/>
        <van-grid square>
          <van-grid-item icon="card" text="待付款" to="orderlist?type=1" />
          <van-grid-item icon="underway" text="待发货" to="orderlist?type=2" />
          <van-grid-item icon="shop-collect" text="待收货" to="orderlist?type=3" />
          <van-grid-item icon="chat" text="评价" to="orderlist?type=4" />
        </van-grid>
        
      </van-cell-group>
      <van-cell-group inset>
        <van-cell title="购物车" icon="shopping-cart"  is-link to="cart"/>
        <van-cell title="收货地址" icon="location-o"  is-link to="address"/>
        <van-cell title="个人信息" icon="friends"  is-link to="user"/>
        <van-cell title="账号设置" icon="bars"  is-link to="useroption"/>
        
      </van-cell-group>
    </div>
</demo-block>
  <!-- 设备选择 -->
  <van-tabbar v-model="active">
    <van-tabbar-item icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item icon="search">分类</van-tabbar-item>
    <van-tabbar-item icon="shopping-cart">购物车</van-tabbar-item>
    <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
  </van-tabbar>

  <van-dialog
    v-model:show="dialogInScene"
    :title="dialogTitle"
    show-cancel-button
    @confirm="InScene"
    :lazy-render="false"
  >
    <img class="imgDialog" :src="require('../../../assets/images/elec.png')" />
  </van-dialog>
  
</template>

<script>
import {
  Grid,
  GridItem,
  Image,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Tag,
  Cell,
  Row,
  Col,
  CouponCell,
  CouponList,
  Popup,
  Icon,
  Dialog,
  Toast,
  Tab,
  Tabs,
  Search,
  Sticky,
  TreeSelect,
  SubmitBar,
  Card,
  Checkbox,
  CheckboxGroup,
  CellGroup,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Button,
  Stepper
} from "vant";
import CartList from '../../components/cartlist.vue'

export default {
  components: {
    [ActionBar.name]: ActionBar,
    [ActionBarIcon.name]: ActionBarIcon,
    [ActionBarButton.name]: ActionBarButton,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    [SubmitBar.name]: SubmitBar,
    [TreeSelect.name]: TreeSelect,
    [CheckboxGroup.name]: CheckboxGroup,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Stepper.name]: Stepper,
    CartList
  },
  watch: {
    sortIndex(val) {
      console.log(val);
      this.sortList = []
      this.goodslist.forEach((info, index) => {
        if (index%5 === val && val<10) {
          this.sortList.push(info);
        } else if (index%3 === (val - 10) && val>10) {
          this.sortList.push(info);
        }
      });
      this.$store.state.sortIndex = val
    },
    activeType(val) {
      console.log('通过id获取商品类型：', val)
      this.GetGoodsList(val)
    },
    active(val) {
      // console.log(this.goodslist)
      if (val === 0) {
        setTimeout(() => {
          console.log('返回主页:', this.$store.state.homeScrollTop);
          document.documentElement.scrollTop = this.$store.state.homeScrollTop;
        }, 300);
       
        // document.body.scrollTop = this.$store.state.homeScrollTop;
      } else {
         if (val === 1) {
          this.sortIndex = this.$store.state.sortIndex;
        } else if (val === 2) {
          this.cartlist = require('../../data/cartlist.json')
        }
        this.$store.state.homeScrollTop = document.documentElement.scrollTop;
      }
      this.$store.state.activeindex = val
    },
    searchid(val) {
      console.log('搜索发生变化', val)
      // this.SearchGoods(val)
    }
  },
  data() {
    return {
      scrollTop: 0,
      activeType: 0,
      checkedGoods: ['1', '2', '3'],
      cartlist: [],
      sortIndex: -1,
      searchid: "",
      goodslist: [],
      dialogIcon: "",
      dialogTitle: "进入模式",
      chartindex: 0,
      dialogInScene: false,
      showScene: false,
      active: 0,
      charttitle: "_________________________________________",
      sortItems: [],
      sortList: [],
      chosenCoupon: -1,
      exchangedCoupons: [],
      images: [
        require("../../../assets/images/swipe001.jpg"),
        require("../../../assets/images/swipe002.jpg"),
        require("../../../assets/images/swipe003.jpg"),
        require("../../../assets/images/swipe004.jpg"),
        require("../../../assets/images/swipe005.jpg"),
      ],
      scenelist: [
        { id: 0, title: "回家", icon: "wap-home" },
        { id: 1, title: "出门", icon: "logistics" },
        { id: 2, title: "睡觉", icon: "underway-o" },
        { id: 3, title: "娱乐", icon: "live" },
      ],
      shoptypelist: [],
      goodsList: [
        { id: 10, image: "home", title: "首页", icon: "wap-home" },
        { id: 11, key: "wgzl", title: "五谷杂粮", icon: "logistics" },
        { id: 12, key: "bg", title: "办公用品", icon: "underway-o" },
        { id: 13, key: "sx", title: "生鲜", icon: "live" },
        { id: 14, key: "xjd", title: "小家电", icon: "live" },
        { id: 15, key: "ryp", title: "日用品", icon: "live" },
      ],
      chartSettings: {
        area: true,
      },
      nInterval: 0
    };
  },
  computed: {
    coupon() {
      return {
        id: 1,
        condition: "智能插座，远程控制",
        reason: "",
        value: 150,
        name: "插座",
        description: "通过NBIOT通讯协议进行通讯，可以实现远程预警及关断",
        startAt: new Date().getTime() / 1000 - 196000,
        endAt: new Date().getTime() / 1000,
        valueDesc: "2",
        unitDesc: "台",
      };
    },
    submitBarText() {
      const count = this.checkedGoods.length;
      return '结算' + (count ? `(${count})` : '');
    },
    totalPrice() {
      return this.cartlist.reduce((total, item) => total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0), 0);
    }
  },
  deactivated() {
    // 保持滚动到的位置
    clearInterval(this.nInterval);
    // this.ScollPostion();
    // console.log(this.scrollTop)
    // this.$store.state.homeScrollTop = this.scrollTop;
    console.log('deactived' , this.$store.state.homeScrollTop);
  },
  activated() {
    //保持滚动到的位置
    this.scrollTop = this.$store.state.homeScrollTop;
    setTimeout(() => {
      console.log('返回主页:', this.scrollTop);
      // Toast('回到原位置');
      document.documentElement.scrollTop = this.scrollTop;
      document.body.scrollTop = this.scrollTop;
    }, 300);
    console.log('actived' , this.scrollTop);
    /*
    this.nInterval=
      setInterval(() => {
        console.log(this.ScollPostion());
        console.log(this.$store.state.homeScrollTop);
      }, 2000);
    */
    console.log('actived' , this.scrollTop);
  },
  mounted() {
    setTimeout(() => {
      console.log('mounted 加载基本商品信息...')
      // 如果商品信息存在就不获取了
      this.GetGoodsList();
      this.sortIndex = this.$store.state.sortIndex
      // console.log(this.goodslist)
    }, 200);
    // 如果发现有active的值，就默认一下
    // console.log(this.$refs);
    if (this.$store.state.activeindex !==0 ) this.active = this.$store.state.activeindex;
    /*
    setInterval(() => {
      this.scrollTop = this.scrollTop + 10;
      document.documentElement.scrollTop = this.scrollTop;
      document.body.scrollTop = this.scrollTop;
    }, 2000);
    */

  },
  methods: {
    ScollPostion() {
      var t =0, l=0, w=0, h=0;
      if(this.active === 0) {
        if (document.documentElement && document.documentElement.scrollTop) {
            t = document.documentElement.scrollTop;
            l = document.documentElement.scrollLeft;
            w = document.documentElement.scrollWidth;
            h = document.documentElement.scrollHeight;
        } else if (document.body) {
            t = document.body.scrollTop;
            l = document.body.scrollLeft;
            w = document.body.scrollWidth;
            h = document.body.scrollHeight;
        }
        console.log(t);
        this.$store.state.homeScrollTop = t;
      }
      return {t, l, w , h}
      
    },
    SearchGoods(val) {
      this.goodslist = []
      const goodslist = require('../../data/goodslist.json');
      if (val === undefined || val === '') this.goodslist = goodslist
      else {
        goodslist.forEach(i => {
          // console.log(i.id % 5)
          if (i.goodinfo.indexOf(val)>=0 || i.hotinfo.indexOf(val)>=0) {
            // console.log('说明是等的')
            this.goodslist.push(i)
          }
        });
      }
    },
    formatPrice(price) {
      return (price / 1).toFixed(2);
    },
    onSubmit() {
      Toast('点击结算');
      this.$router.push('order');
    },
    ClickBuy (param) {
      // console.log(param, a);
      this.ScollPostion();
      // Toast(param)
      setTimeout(() => {
        this.$router.push( 'goods?id=' + param );        
      }, 300);
    },
    GetGoodsList (val) {
      this.goodslist = []
      const goodslist = require('../../data/goodslist.json');
      const sortItems = require('../../data/goodscategory.json');
      this.$store.state.goodslist = goodslist;
      this.$store.state.sortItems = sortItems;
      this.shoptypelist = this.$store.state.shoptypelist;
      this.sortItems = sortItems
      if (val === undefined || val === '') this.goodslist = goodslist
      else {
        goodslist.forEach(i => {
          // console.log(i.id % 5)
          if (i.id % 6 === val) {
            // console.log('说明是等的')
            if (this.searchid !== '' && (i.goodinfo.indexOf(this.searchid)>=0 || i.hotinfo.indexOf(this.searchid)>=0)) this.goodslist.push(i)
            else this.goodslist.push(i)
          }
        });
      }
      // console.log(this.goodslist)

    },
    beforeClose(action, done) {
      if (action === "confirm") {
        setTimeout(done, 1000);
      } else {
        done();
      }
    },
    showPop(s) {
      if (s === undefined) {
        this.dialogTitle = "进入自定义模式";
        this.dialogIcon = "https://img01.yzcdn.cn/vant/logo.png";
      } else {
        this.dialogTitle = "进入" + s.title + "模式";
        this.dialogIcon = s.icon;
      }
      this.dialogInScene = true;
    },
    InScene() {
      console.log("ok");
      Toast({
        message: this.dialogTitle + "成功",
        icon: this.dialogIcon,
      });
    },
    onChangeScene(index) {
      this.showScene = false;
      this.chosenCoupon = index;
    },
    onExchange() {
      this.$toast("exchange");
      this.exchangedCoupons.push({
        ...this.coupon,
        id: this.randomId(),
      });
    },
    randomId(max = 999999) {
      return Math.floor(Math.random() * max) + 1;
    },
    onChange(index) {
      this.current = index;
    },
    onSearch(val) {
      Toast('开始搜索：' + val)
      console.log(val)
      this.SearchGoods(val)
      Toast('搜索完成')
    },
  },
};
</script>

<style lang="less">
@import '../../styles/var';

.van-grid {
  display: flex;
  flex-wrap: wrap;
}
.img1 {
  height: 150px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.imggood {
  height: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.goodinfo {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 12px;
  font-weight: 80;
}
.imgDialog {
  box-sizing: border-box;
  width: 100%;
  padding: 25px 20px 0;
}
.cny {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  color: rgb(233,22,2)
}
.van-col {
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    font-size: 13px;
    line-height: 20px;
    float: left;
  
  }
.van-col-right {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 30px;
    text-align: right;
  }
  .bgcolor {
    background-color:rgb(228, 225, 225);
    // margin-bottom: 100px;
    
  }
  .goods-item-wrapper {
    box-sizing: border-box;
    position: relative;
    float: left;
    min-height: 1px;
    width: 180px;
    height: 300px;
    background-color:rgb(255, 251, 251);
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .goods-item-wrapper:hover {
			display: block;
			background-color: rgb(150, 143, 143);
			transition: all 0.1s; 
		}
.goods-item-wrapper:last-child
{ 
  margin-bottom: 70px;
}
.card-goods {
  padding: 10px 20px;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
.demo-cell {
  .custom-title {
    margin-right: 4px;
    margin-left: 5px;
    vertical-align: middle;
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }
}
.van-cell-group {
  margin-bottom: 15px;
}
.title-img {
  padding-top: 10px;
  display: block;
  height: 10px;
  position: relative;
  max-width: 100%;
  background-size:100% 100%;
  // background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.m-img {
  padding-bottom: 33%;
  display: block;
  height: 10px;
  position: relative;
  max-width: 100%;
  background-size:100% 100%;
  // background-size: cover;
  background-position: center center;
  cursor: pointer;
  // border-radius: 10px;
}

</style>
